import { ItemReorderEventDetail } from "@ionic/core"
import { IonButton, IonButtons, IonContent, IonItem, IonLabel, IonList, IonModal, IonReorder, IonReorderGroup, IonToolbar } from "@ionic/react"
import React from "react"
import { useState } from "react"
import { editCategory } from "../ServerProvider"

declare global {
    interface Array<T>  {
        move(from: number, to: number): void;
    }
}

// eslint-disable-next-line
Array.prototype.move = function (from: number, to: number) {
    this.splice(to, 0, this.splice(from, 1)[0]);
};

const ReorderSub: React.FC<{subcategories: string[], category: string}> = (props) => {
    const [modalOpen, setModalOpen] = useState(false)

    const doReorder = (event: CustomEvent<ItemReorderEventDetail>) => {
        props.subcategories.move(event.detail.from, event.detail.to)
        editCategory(props.category, "subcategories", props.subcategories)
        event.detail.complete();
    }

    return <>
        <IonButton onClick={() => setModalOpen(true)}>Edit Subcategory Placement</IonButton>
        <IonModal isOpen={modalOpen}>
            <IonToolbar>
                <IonButtons slot="primary">
                    <IonButton onClick={() => setModalOpen(false)}>Done</IonButton>
                </IonButtons>
            </IonToolbar>

            <IonContent>
                <IonList>
                    <IonReorderGroup disabled={false} onIonItemReorder={doReorder}>
                        {props.subcategories.map((subcat, i) => <IonItem key={i}>
                            <IonLabel>{subcat} {i}</IonLabel>
                            <IonReorder slot="end" />
                        </IonItem>)}
                    </IonReorderGroup>
                </IonList>
            </IonContent>
        </IonModal>
    </>
}

export default ReorderSub