import React, { useState } from 'react';
import { IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonItem, IonButton, IonAlert, IonLabel } from '@ionic/react';
import { removeProduct } from '../ServerProvider';
import { Redirect } from 'react-router';

const Product: React.FC<{product: any, warehouse: boolean, qcHandler: ((q: number) => void) | null, readOnly: boolean}> = (props) => {
    const [quantity, setQuantity] = useState(props.product.quantity ? props.product.quantity : 1)
    const [atca, setAtca] = useState(false)
    const [eP, setEP] = useState(false)
    const [qA, setqA] = useState(false)

    const sQuantity = (q: number) => {
        if (props.qcHandler) {
            props.qcHandler(q)
        }
        setQuantity(q)
    }
    return (
        <IonCard>
            {eP ? <Redirect to="/warehouse/editProduct" /> : ""}
            <img alt="" src={"data:image/jpeg;base64," + props.product.image}  />
            <IonCardHeader>
                <IonCardSubtitle>{props.product.brand}</IonCardSubtitle>
                <IonCardTitle>{props.product.name}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <b>SKU</b> {props.product.sku} <br /> 
                <b>Category</b> {props.product.category} <br />
                {(() => {
                    if (props.product.subcategory) {
                        return (
                            <><b>Subcategory</b> {props.product.subcategory} <br /></>
                        )
                    }
                })()}
                {(() => {
                    if (props.product.location) {
                        return (
                            <><b>Location</b> {props.product.location} <br /></>
                        )
                    }
                })()}
                <b>Info</b> {props.product.info}
            </IonCardContent>
            <IonItem>
            {(props.readOnly ? <IonLabel>{props.product.quantity}</IonLabel> : (props.warehouse ? [<IonButton onClick={() => removeProduct(props.product.sku)} color="danger" fill="clear" slot="end">Remove Product</IonButton>, <IonButton onClick={() => {setEP(true); window.localStorage.setItem("product", JSON.stringify(props.product))}} fill="clear">Edit Product</IonButton>] : (
                    <div className="buttons">
                        <IonButton slot="start" onClick={() => quantity > 1 ? sQuantity(quantity - 1): null}>-</IonButton>&nbsp;&nbsp;
                        <div onClick={() => setqA(true)}>{quantity}&nbsp;&nbsp;</div>
                        <IonAlert isOpen={qA} header="Set Quantity" 
                            inputs={[{name: "quantity", type: "number", min: 0, value: quantity}]}
                            buttons={[{text: "Done", handler: (val) => {setQuantity(val.quantity); setqA(false)}}, {text: "Cancel", role: "cancel", handler: () => setqA(false)}]}
                            onDidDismiss={() => setqA(false)}
                        ></IonAlert>
                        <IonButton slot="start" onClick={() => sQuantity(quantity + 1)}>+</IonButton>
                        {props.qcHandler ? <IonButton onClick={() => sQuantity(0)} color="danger" fill="clear" slot="end">Remove Product</IonButton> : <IonButton color="success" fill="clear" slot="end" onClick={() => {
                            const cart = JSON.parse((window.localStorage.getItem("cart") ? (window.localStorage.getItem("cart") as string) : "{}"))
                            let set = false
                            if (!cart.items) {
                                cart.items = []
                            }
                            for (let i = 0; i < cart.items.length; i++) {
                                if (cart.items[i].sku === props.product.sku) {
                                    cart.items[i].quantity += quantity
                                    set = true
                                    break
                                }
                            }
                            if (!set) {
                                cart.items.push({sku: props.product.sku, quantity: quantity})
                            }
                            window.localStorage.setItem("cart", JSON.stringify(cart))
                            setAtca(true)
                        }}>Add to Cart</IonButton>}
                    </div>
                )))}
            </IonItem>
            <IonAlert isOpen={atca} header="Added to cart" message={`${quantity} ${props.product.name} ${quantity === 1 ? "was" : "were"} added to the cart`} buttons={[{text: "OK", handler: () => setAtca(false)}]}></IonAlert>
        </IonCard>
    )
}

export default Product;