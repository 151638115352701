import { IonItem, IonButton, IonModal, IonToolbar, IonButtons, IonLabel, IonInput } from "@ionic/react"
import React, { useState } from "react"
import { removeCategory, setCategoryImage, setCategoryName } from "../ServerProvider"

const CategoryImage: React.FC<{category: string}> = (props) => {
    const [modalOpen, setModalOpen] = useState(false)
    const [category, setCategory] = useState(props.category)
    const [imageFile, setImageFile] = useState(null as (File | null))

    const submitImage = () => {
        const reader = new FileReader()

        if (imageFile != null) {
            reader.readAsDataURL(imageFile)
        }

        reader.onloadend = () => setCategoryImage(props.category, (reader.result as string).split(',')[1]).then(() => setModalOpen(false))
    }
    
    const submitCatName = () => {
        setCategoryName(props.category, category).then(() => {
            setModalOpen(false)
        })
    }

    const remove = () => {
        setCategoryImage(props.category, "").then(() => setModalOpen(false))
    }
    return (
        <>
            <IonButton onClick={() => setModalOpen(true)}>Edit Category</IonButton>
            <IonModal isOpen={modalOpen}>
                <IonToolbar>
                    <IonButtons slot="primary">
                        <IonButton onClick={() => setModalOpen(false)}>Cancel</IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonItem>
                    <IonLabel position="stacked">Category Name</IonLabel>
                    <IonInput value={category} required type="text" onIonChange={e => {setCategory((e.target as HTMLInputElement).value)}}></IonInput>
                </IonItem>
                <IonButton disabled={!category} onClick={submitCatName} expand="full">Change Category Name</IonButton>
                <IonItem>
                    <IonLabel position="stacked">Image</IonLabel>
                    <input type="file" onChange={(event) => {
                    if (event.target.files) {
                        setImageFile(event.target.files[0])
                    }
                    }}></input>
                
                </IonItem>
                <IonButton onClick={remove} expand="full">Remove Image</IonButton>
                <IonButton disabled={!imageFile} onClick={submitImage} expand="full">Submit Image</IonButton>
                <IonButton onClick={() => {removeCategory(props.category); setModalOpen(false)}} expand="full">Remove Category</IonButton>
            </IonModal>
        </>
    )
}

export default CategoryImage;