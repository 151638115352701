import { IonContent, IonHeader, IonPage, IonList, IonLabel, IonItem, IonTitle, IonToolbar, IonInput, IonButton, IonAlert, IonButtons, IonBackButton, IonModal, IonRadioGroup, IonRadio } from '@ionic/react';
import React from 'react';
import { addProduct, getCategories, addCategory, addSubcategory, editProduct } from '../ServerProvider';
import { Redirect } from 'react-router';
import { IconButton } from './Products';

class AddProduct extends React.Component<{product: any}, {[key: string]: any}> {

  constructor(props: any) {
    super(props);

    this.state = {
      name: "",
      location: "",
      sku: "",
      originalSKU: this.props.product ? this.props.product.sku : "",
      brand: "",
      imageFile: "",
      categories: [],
      category: "",
      subcategory: "",
      info: "",
      cas: false,
      aca: false,
      sas: false,
      asa: false,
      goBack: false,
      imData: this.props.product ? this.props.product.image : "",
      barcode: ""
    }

    if (this.props.product) {
      this.state = {...this.state, ...props.product}
    }

    getCategories().then((categories) => {
      this.setState({categories})
    })
  }

  submit() {
    const reader = new FileReader()
    console.log(this.state)
    
    const done = () => {
      if (this.state.imageFile) {
        this.setState({imData: (reader.result as string).split(',')[1]})
      }

      if (this.props.product) {
        editProduct(this.state.originalSKU, {name: this.state.name, category: this.state.category, subcategory: this.state.subcategory, brand: this.state.brand, image: this.state.imData, info: this.state.info, location: this.state.location, sku: this.state.sku, barcode: this.state.barcode}).then(() => this.setState({goBack: true}))
        console.log(this.state.imData)
      } else {
        addProduct(this.state.name, this.state.sku, this.state.category, this.state.subcategory, this.state.brand, this.state.imData, this.state.info, this.state.location, this.state.barcode).then(() => {
          this.setState({goBack: true})
        })
      }
    }

    if (this.state.imageFile) {
      reader.readAsDataURL(this.state.imageFile)
    } else {
      done()
    }

    reader.onloadend = done
  }

  render() {
    if (this.state.goBack) {
      return <Redirect push={false} to="/warehouse/products" />
    }
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle color="primary">{this.props.product ? 'Edit Product' : 'Add Product'}</IonTitle>
            <IonButtons slot="start">
              <IonBackButton />
            </IonButtons>
            <IonButtons slot="end">
              <IconButton />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <form>
            <IonList>
              <IonItem>
                <IonLabel position="stacked">Product Name</IonLabel>
                <IonInput value={this.state.name} required type="text" onIonChange={event => {this.setState({name: (event.target as HTMLInputElement).value})}}></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">Location</IonLabel>
                <IonInput value={this.state.location} required type="text" onIonChange={event => {this.setState({location: (event.target as HTMLInputElement).value})}}></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">SKU</IonLabel>
                <IonInput value={this.state.sku}required type="text" onIonChange={event => {this.setState({sku: (event.target as HTMLInputElement).value})}}></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">Barcode (must be a 13 digit EAN)</IonLabel>
                <IonInput value={this.state.barcode}required type="text" onIonChange={event => {this.setState({barcode: (event.target as HTMLInputElement).value})}}></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">Category</IonLabel>
                {this.state.category ? <p>{this.state.category}</p> : null}
                <IonButton onClick={() => this.setState({cas: true})}>{this.state.category ? "Change Category" : "Select Category"}</IonButton>
                <IonModal cssClass="opaque" isOpen={this.state.cas} onDidDismiss={() => this.setState({cas: false})}>
                  <IonToolbar>
                      <IonButtons slot="primary">
                          <IonButton onClick={() => this.setState({cas: false})}>Cancel</IonButton>
                      </IonButtons>
                  </IonToolbar>
                  <IonContent>
                    <IonButton onClick={() => this.setState({aca: true})} expand="full">Add Category</IonButton>
                    <IonButton onClick={() => this.setState({category: ""})} expand="full">Remove Category</IonButton>
                    <IonList>
                      <IonRadioGroup value={this.state.category} onIonChange={e => this.setState({category: e.detail.value})}>
                        {this.state.categories.map((category: any) => {
                          return <IonItem>
                            <IonLabel>{category.name}</IonLabel>
                            <IonRadio slot="end" value={category.name}></IonRadio>
                          </IonItem>
                        })}
                      </IonRadioGroup>
                    </IonList>
                  </IonContent>
                </IonModal>
                <IonAlert
                  isOpen={this.state.aca}
                  onDidDismiss={() => this.setState({aca: false})}
                  header={'Add Category'}
                  inputs={[
                    {
                      name: 'category',
                      type: 'text',
                      placeholder: 'Category'
                    },
                  ]}
                  buttons={[{
                    text: "Add",
                    handler: (value) => {
                      addCategory(value.category).then(() => {
                        const categories = this.state.categories
                        categories.push({name: value.category})
                        this.setState({categories})
                      })
                    }
                  }, 'Cancel']}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">Subcategory</IonLabel>
                {this.state.subcategory ? <p>{this.state.subcategory}</p> : null}
                <IonButton disabled={!(this.state.category)} onClick={() => this.setState({sas: true})}>{this.state.subcategory ? "Change Subcategory" : "Select Subcategory"}</IonButton>
                <IonModal cssClass="opaque" isOpen={this.state.sas} onDidDismiss={() => this.setState({sas: false})}>
                  <IonToolbar>
                      <IonButtons slot="primary">
                          <IonButton onClick={() => this.setState({sas: false})}>Cancel</IonButton>
                      </IonButtons>
                  </IonToolbar>
                  <IonContent>
                    <IonButton onClick={() => this.setState({asa: true})} expand="full">Add Subcategory</IonButton>
                    <IonButton onClick={() => this.setState({subcategory: ""})} expand="full">Remove Subcategory</IonButton>
                    <IonList>
                      <IonRadioGroup value={this.state.subcategory} onIonChange={e => this.setState({subcategory: e.detail.value})}>
                        {(() => {
                          for (let category of this.state.categories) {
                            if (category.name === this.state.category) {
                              if (category.subcategories) {
                                return category.subcategories.map((subcategory: any) => {
                                  console.log(subcategory)
                                  return <IonItem>
                                    <IonLabel>{subcategory}</IonLabel>
                                    <IonRadio slot="end" value={subcategory}></IonRadio>
                                  </IonItem>
                                })
                              }
                            }
                          }
                      })()}
                      </IonRadioGroup>
                    </IonList>
                  </IonContent>
                </IonModal>
                <IonAlert
                  isOpen={this.state.asa}
                  onDidDismiss={() => this.setState({asa: false})}
                  header={'Add Subcategory'}
                  inputs={[
                    {
                      name: 'subcategory',
                      type: 'text',
                      placeholder: 'Subcategory'
                    },
                  ]}
                  buttons={[{
                    text: "Add",
                    handler: (value) => {
                      addSubcategory(this.state.category, value.subcategory).then(() => {
                        const categories = this.state.categories
                        for (let i = 0; i < categories.length; i++) {
                          if (categories[i].name === this.state.category) {
                            if (categories[i].subcategories) {
                              categories[i].subcategories.push(value.subcategory)
                            } else {
                              categories[i].subcategories = [value.subcategory]
                            }
                          }
                        }
                        this.setState({categories})
                      })
                    }
                  }, 'Cancel']}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">Brand</IonLabel>
                <IonInput value={this.state.brand} required type="text" onIonChange={event => {this.setState({brand: (event.target as HTMLInputElement).value})}}></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">Info</IonLabel>
                <IonInput value={this.state.info} required type="text" onIonChange={event => {this.setState({info: (event.target as HTMLInputElement).value})}}></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">Image</IonLabel>
                <input type="file" onChange={(event) => {
                  if (event.target.files) {
                    this.setState({imageFile: event.target.files[0]})
                  }
                }}></input>
              </IonItem>
              <br />
              <IonButton onClick={this.submit.bind(this)} expand="full">Submit</IonButton>
            </IonList>
          </form>
        </IonContent>
      </IonPage>
    );
  }
}

export default AddProduct;
