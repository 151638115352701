import React, { useState } from 'react';
import { IonButtons, IonButton, IonModal, IonToolbar, IonTitle, IonContent, IonAlert, IonList, IonItem, IonLabel, IonInput, IonIcon } from '@ionic/react';
import { getProduct, makeOrder } from '../ServerProvider';
import { uuidv4 } from '../Serverconn';
import { get } from './Orders';
import { cart as carticon } from 'ionicons/icons';
import SmallProduct from './SmallProduct';

const CartButton: React.FC = () => {
    const [showModal, setShowModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [cart, setCart] = useState([] as any[]);

    const [name, setName] = useState(get("name"))
    const [email, setEmail] = useState(get("email"))
    const [phoneNumber, setPhonenumber] = useState(get("phonenumber"))
    const [location, setLocation] = useState(get("location"))
    const [jobName, setJobName] = useState(get("jobName"))
    const [dateNeeded, setDateNeeded] = useState(get("dateNeeded"))
    const [pmName, setPMName] = useState(get("pmName"))
    const [jobNumber, setJobNumber] = useState(get("jobNumber"))
    const [deliveryInstructions, setDeliveryInstructions] = useState(get("deliveryInstructions"))
    

    const sCart = (arr: any[]) => {
        const iCart: {items: any[]} = {items: []}

        let i = 0
        for (let item of arr) {
            if (item.quantity !== 0) {
                iCart.items.push({sku: item.sku, quantity: item.quantity})
            } else {
                arr.splice(i, 1)
            }
            i++
        } 

        window.localStorage.setItem("cart", JSON.stringify(iCart))

        // if (cart != arr) {
            setCart(arr)
        // }
    }

    const getCart = async () => {
        const iCart = JSON.parse(window.localStorage.getItem("cart") ? window.localStorage.getItem("cart") as string : "{\"items\": []}")

        const iiCart = []
        for (let item of iCart.items) {
            const product = await getProduct(item.sku)

            product.quantity = item.quantity
            iiCart.push(product)
        }

        sCart(iiCart)
    }

    const getICart = () => {
        const iCart: any[] = []

        cart.forEach(item => {
            iCart.push({sku: item.sku, quantity: item.quantity})
        }) 

        return iCart
    }

    const submitOrder = () => {
        const uuid = uuidv4()
        makeOrder(getICart(), uuid, name, email, phoneNumber, location, jobName, dateNeeded, pmName, jobNumber, deliveryInstructions).then(() => {
            window.localStorage.setItem("name", name)
            window.localStorage.setItem("email", email)
            window.localStorage.setItem("phonenumber", phoneNumber)
            window.localStorage.setItem("location", location)
            window.localStorage.setItem("jobName", jobName)
            window.localStorage.setItem("dateNeeded", dateNeeded)
            window.localStorage.setItem("pmName", pmName)
            window.localStorage.setItem("jobNumber", jobNumber)
            window.localStorage.setItem("deliveryInstructions", deliveryInstructions)
            setShowModal(false);
            setShowAlert(true);
            const previousOrders = JSON.parse(window.localStorage.getItem("previousOrders") ? window.localStorage.getItem("previousOrders") as string : "[]")
            previousOrders.unshift({uuid: uuid, order: getICart(), timestamp: Date.now() / 1000, name, location, jobName, dateNeeded, pmName, jobNumber, deliveryInstructions})
            window.localStorage.setItem("previousOrders", JSON.stringify(previousOrders))

            window.localStorage.setItem("cart", "")
        })
    }

    // setTimeout(() => {
    //     getCart()
    // }, 1000)
    
    return (
    <IonButtons slot="primary">
        <IonAlert isOpen={showAlert} header="Success" message="Your order was received." buttons={[{text: "Okay", handler: () => setShowAlert(false)}]} onDidDismiss={() => setShowAlert(false)}></IonAlert>
        <IonButton fill="clear" onClick={() => {getCart(); setShowModal(true)}}>
            <IonIcon icon={carticon} slot="start"></IonIcon>
            Cart {cart.length > 0 ? "(" + cart.length + ")" : null}
        </IonButton>
        <IonModal cssClass="opaque" isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
            <IonToolbar>
                <IonTitle color="primary">{`${cart.length} ${cart.length === 1 ? "item" : "items"} in the cart`}</IonTitle>
                <IonButtons slot="primary">
                    <IonButton onClick={() => setShowModal(false)}>Continue Shopping</IonButton>
                </IonButtons>
            </IonToolbar>
            <IonContent>
                <h1>&ensp;Your Order</h1>
                <IonList>
                    {cart.map((item, key) => <SmallProduct key={key} product={item} warehouse={false} qcHandler={q => {
                        let iCart = cart;
                        for (let i = iCart.length - 1; i >= 0; i--) {
                            if (iCart[i].sku === item.sku) {
                                iCart[i].quantity = q; 
                            }
                        }
                        sCart(iCart)
                        console.log(cart)
                    }} readOnly={false}></SmallProduct>)}
                </IonList>
                <h1>&ensp;Your Information</h1>
                <IonList>
                    <IonItem>
                        <IonLabel position="stacked">Name</IonLabel>
                        <IonInput value={name} required type="text" onIonChange={e => {setName((e.target as HTMLInputElement).value)}}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">Email</IonLabel>
                        <IonInput value={email} type="text" onIonChange={e => {setEmail((e.target as HTMLInputElement).value)}}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">Phone Number</IonLabel>
                        <IonInput value={phoneNumber} required type="text" onIonChange={e => {setPhonenumber((e.target as HTMLInputElement).value)}}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">Delivery Address</IonLabel>
                        <IonInput value={location} required type="text" onIonChange={e => {setLocation((e.target as HTMLInputElement).value)}}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">Job Name</IonLabel>
                        <IonInput value={jobName} required type="text" onIonChange={e => {setJobName((e.target as HTMLInputElement).value)}}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">Date Needed</IonLabel>
                        <IonInput value={dateNeeded} required type="text" onIonChange={e => {setDateNeeded((e.target as HTMLInputElement).value)}}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">PM Name</IonLabel>
                        <IonInput value={pmName} required type="text" onIonChange={e => {setPMName((e.target as HTMLInputElement).value)}}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">Job Number</IonLabel>
                        <IonInput value={jobNumber} required type="text" onIonChange={e => {setJobNumber((e.target as HTMLInputElement).value)}}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">Delivery Instructions</IonLabel>
                        <IonInput value={deliveryInstructions} required type="text" onIonChange={e => {setDeliveryInstructions((e.target as HTMLInputElement).value)}}></IonInput>
                    </IonItem>
                </IonList>
                <IonButton disabled={cart.length < 1 || !name || !location || !jobName || !dateNeeded || !pmName || !jobNumber || !deliveryInstructions} onClick={submitOrder} expand="full">Submit Order</IonButton>
            </IonContent>
        </IonModal>
    </IonButtons>
    )
}

export default CartButton;