import React, { useState } from 'react';
import { IonItem, IonButton, IonAlert, IonLabel, IonThumbnail, IonNote } from '@ionic/react';

const SmallProduct: React.FC<{product: any, warehouse: boolean, qcHandler: ((q: number) => void) | null, readOnly: boolean}> = (props) => {
    const [quantity, setQuantity] = useState(props.product.quantity ? props.product.quantity : 1)
    const [atca, setAtca] = useState(false)
    const [qA, setqA] = useState(false)

    const sQuantity = (q: number) => {
        if (props.qcHandler) {
            props.qcHandler(q)
        }
        setQuantity(q)
    }
    return (
        <IonItem>
            {/* {eP ? <Redirect to="/warehouse/editProduct" /> : ""} */}
            <IonThumbnail slot="start">
                <img alt="" src={"data:image/jpeg;base64," + props.product.image}  />
            </IonThumbnail>
            <IonLabel>
                <h2>{props.product.name} ({props.product.sku})</h2>
                <p>{props.product.category} <br />{(props.product.subcategory ? props.product.subcategory: null)}</p>
            </IonLabel>
            <IonNote>
                <div className="buttons">
                    <IonButton slot="start" onClick={() => quantity > 1 ? sQuantity(quantity - 1): null}>-</IonButton>&nbsp;&nbsp;
                    <div onClick={() => setqA(true)}>{quantity}&nbsp;&nbsp;</div>
                        <IonAlert isOpen={qA} header="Set Quantity" 
                            inputs={[{name: "quantity", type: "number", min: 0, value: quantity}]}
                            buttons={[{text: "Done", handler: (val) => {sQuantity(val.quantity); setqA(false)}}, {text: "Cancel", role: "cancel", handler: () => setqA(false)}]}
                            onDidDismiss={() => setqA(false)}
                        ></IonAlert>
                    <IonButton slot="start" onClick={() => sQuantity(quantity + 1)}>+</IonButton>
                    {props.qcHandler ? <IonButton onClick={() => sQuantity(0)} color="danger" fill="clear" slot="end">Remove Product</IonButton> : <IonButton color="success" fill="clear" slot="end" onClick={() => {
                        const cart = JSON.parse((window.localStorage.getItem("cart") ? (window.localStorage.getItem("cart") as string) : "{}"))
                        let set = false
                        if (!cart.items) {
                            cart.items = []
                        } 
                        for (let i = 0; i < cart.items.length; i++) {
                            if (cart.items[i].sku === props.product.sku) {
                                cart.items[i].quantity += quantity
                                set = true
                                break
                            }
                        }
                        if (!set) {
                            cart.items.push({sku: props.product.sku, quantity: quantity})
                        }
                        window.localStorage.setItem("cart", JSON.stringify(cart))
                        setAtca(true)
                    }}>Add to Cart</IonButton>}
                </div>
            </IonNote>
            <IonAlert isOpen={atca} header="Added to cart" message={`${quantity} ${props.product.name} ${quantity === 1 ? "was" : "were"} added to the cart`} buttons={[{text: "OK", handler: () => setAtca(false)}]}></IonAlert>
        </IonItem>
    )
}

export default SmallProduct;