import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupConfig } from '@ionic/react';
import { IonReactHashRouter } from '@ionic/react-router';
import Home from './pages/Home';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './main.css';

import Warehouse from './pages/Warehouse';
import Orders, { get } from './pages/Orders';
import Products from './pages/Products';
import AddProduct from './pages/AddProduct';

setupConfig({
  mode: 'ios'
});

// Use matchMedia to check the user preference
const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

toggleDarkTheme(prefersDark.matches);

// Listen for changes to the prefers-color-scheme media query
prefersDark.addListener((mediaQuery) => toggleDarkTheme(mediaQuery.matches));

// Add or remove the "dark" class based on if the media query matches
function toggleDarkTheme(shouldAdd: any) {
  document.body.classList.toggle('dark', shouldAdd);
}

const App: React.FC = () => (
    <IonApp>
      <IonReactHashRouter>
        <IonRouterOutlet>
          <Route path="/warehouse" component={Warehouse} exact={true} />
          <Route path="/warehouse/orders" render={(props) => <Orders {...props} warehouse={true} />} exact={true} />
          <Route path="/warehouse/products" render={(props) => <Products {...props} warehouse={true} />} exact={true}/>
          <Route path="/warehouse/products/:category" render={(props) => <Products {...props} warehouse={true} />} exact={true}/>
          <Route path="/warehouse/products/:category/:subcategory" render={(props) => <Products {...props} warehouse={true} />} exact={true}/>
          <Route path="/field/" render={(props) => <Products {...props} warehouse={false} />} exact={true}/>
          <Route path="/field/products/:category" render={(props) => <Products {...props} warehouse={false} />} exact={true}/>
          <Route path="/field/products/:category/:subcategory" render={(props) => <Products {...props} warehouse={false} />} exact={true}/>
          <Route path="/field/orders" render={(props) => <Orders {...props} warehouse={false} />} exact={true}/>
          <Route path="/warehouse/editProduct" render={(props) => <AddProduct {...props} product={JSON.parse(get("product"))} />} exact={true}/>
          <Route path="/warehouse/addProduct" component={AddProduct} exact={true} />
          <Route path="/home" component={Home} exact={true} />
          <Route exact={true} path="/" render={() => {
            if (!localStorage.getItem("auth")) {
              return <Redirect to="/home" />
            }
            const mode = window.localStorage.getItem('mode')
            if (mode === 'field') {
              return <Redirect to="/field" />
            } else if (mode === 'warehouse') {
              return <Redirect to="/warehouse" />
            } else {
              return <Redirect to="/home" />
            }
          }} />
        </IonRouterOutlet>
      </IonReactHashRouter>
    </IonApp>
);

export default App;
