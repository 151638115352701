
import { add } from './Serverconn'

function creator<T>(simple: boolean, request: any, inner: string = "") {
    return new Promise<T>((res, rej) => {
        add(request, (data) => {
            if (data.error) {
                rej(data.error)
            } else {
                if (simple) {
                    res(true as any)
                } else {
                    if (inner) {
                        res(data[inner])
                    } else {
                        res(data)
                    }
                }
            }
        })
    })
}

export const addProduct = (name: string, sku: string, category: string, subcategory: string | null, brand: string, image: string, info: string, location: string | null, barcode: string|null)  => creator<boolean>(true, {request: 'addProduct', product: {name, sku, category, subcategory, brand, image, info, location, barcode}})
export const getProduct = (sku: string) => creator<any>(false, {request: 'getProduct', sku})
export const removeProduct = (sku: string) => creator<boolean>(true, {request: 'removeProduct', sku})
export const addCategory = (category: string) => creator<boolean>(true, {request: 'addCategory', category})
export const addSubcategory = (category: string, subcategory: string) => creator<boolean>(true, {request: 'addSubcategory',  category, subcategory})
export const getCategories = () => creator<any[]>(false, {request: 'getCategories'}, "items")
export const getCategory = (category: string) => creator<any>(false, {request: 'getCategory', category})
export const removeSubcategory = (category: string, subcategory: string) => creator<boolean>(true, {request:   'removeSubcategory', category, subcategory})
export const removeCategory = (category: string) => creator<boolean>(true, {request: 'removeCategory', category})
export const getProductsBySearch = (category: string | null, subcategory: string | null, keyword: string | null, callback:  (product: any) => void) => add({request: 'getProductsBySearch', category, subcategory, keyword}, callback)
export const makeOrder = (products: any[], uuid: string, name: string, email: string, phonenumber: string, location: string, jobName: string, dateNeeded: string, pmName: string, jobNumber: string, deliveryInstructions: string) => creator<boolean>(true, {request: 'makeOrder', order: {products, uuid, timestamp: Date.now() / 1000, name, email, phonenumber, location, jobName, dateNeeded, pmName, jobNumber, deliveryInstructions}})
export const getOrders = () => creator<any>(false, {request: 'getOrders'})
export const getOrder = (uuid: string) => creator<any>(false, {request: 'getOrder', uuid})
export const markOrderAsFullfilled = (uuid: string) => creator<boolean>(true, {request: 'markOrderAsFullfilled', uuid})
export const getCategoryImages = (category: string = "") => creator<string[]>(false, {request: 'getCategoryImages', category}, "images") 
export const editCategory = (category: string, key: string, value: any) => creator<boolean>(false, {request: 'editCategory', category, key, value}) 
export const setCategoryImage = (category: string, image: string) => creator<boolean>(true, {request: 'setCategoryImage', category, image})
export const setCategoryName = (category: string, category2: string) => creator<boolean>(true, {request: 'setCategoryName', category, category2})
export const getOrdersByName = (name: string) => creator<any[]>(false, {request: 'getOrdersByName', name}, "items")
export const editProduct = (sku: string, product: any) => creator<boolean>(true, {request: 'editProduct', originalSKU: sku, product})
export const auth = (token: string) => creator<any>(false, {request: "auth", token})