import React, { useState } from 'react'
import { IonButton, IonAlert, IonIcon } from "@ionic/react"

import { get } from "./Orders"
import { contact } from 'ionicons/icons'

const SetName: React.FC = () => {
    const [showAlert, setShowAlert] = useState(false)
    return (
        <IonButton onClick={() => setShowAlert(true)}><IonIcon slot="starts" name="contact" />
        <IonIcon icon={contact} slot="start" />
            User
            <IonAlert isOpen={showAlert} header="Set Info" inputs={[
                {name: "name", placeholder: "Name", type: "text", value: get("name")}, 
                {name: "email", placeholder: "Email", type: "text", value: get("email")}, 
                {name: "phonenumber", placeholder: "Phone Number", type: "text", value: get("phonenumber")}, 
                {name: "location", placeholder: "Location", type: "text", value: get("location")},
                {name: "jobName", placeholder: "Job Name", type: "text", value: get("jobName")},
                {name: "dateNeeded", placeholder: "Date Needed", type: "text", value: get("dateNeeded")},
                {name: "pmName", placeholder: "PM Name", type: "text", value: get("pmName")},
                {name: "jobNumber", placeholder: "Job Number", type: "text", value: get("jobNumber")},
                {name: "deliveryInstructions", placeholder: "Delivery Instructions", type: "text", value: get("deliveryInstructions")},
            ]} buttons={[{text: "Done", handler: (value) => {
                window.localStorage.setItem("name", value.name)
                window.localStorage.setItem("email", value.email)
                window.localStorage.setItem("location", value.location)
                window.localStorage.setItem("jobName", value.jobName)
                window.localStorage.setItem("dateNeeded", value.dateNeeded)
                window.localStorage.setItem("pmName", value.pmName)
                window.localStorage.setItem("jobNumber", value.jobNumber)
                window.localStorage.setItem("deliveryInstructions", value.deliveryInstructions)
                window.localStorage.setItem("phonenumber", value.phonenumber)
                }}]} onDidDismiss={() => setShowAlert(false)}></IonAlert>
        </IonButton>
    )
}

export default SetName;