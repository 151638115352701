import { IonContent, IonHeader, IonPage, IonList, IonLabel, IonItem, IonTitle, IonToolbar, IonButtons, IonBackButton, IonNote, IonModal, IonButton, IonGrid, IonRow, IonCol, IonAlert } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import Product from './Product';
import { getProduct, getOrder, markOrderAsFullfilled, getOrders, getOrdersByName } from '../ServerProvider';
import CartButton from './Cart';
import { IconButton } from './Products';

export const get = (id: string) => {
  return window.localStorage.getItem(id) ? window.localStorage.getItem(id) as string : ""
}

const Orders: React.FC<{ warehouse: boolean }> =  (props) => {

  const [showProduct, setShowProduct] = useState(-1)
  const [showAlert, setShowAlert] = useState(false)
  const [showAlert2, setShowAlert2] = useState(false)
  const [products, setProducts] = useState([] as any[])
  const [isFullFilled, setIsFullfilled] = useState(false)
  const [orders, setOrders] = useState([] as any[])

  const gOrders = () => {
    if (props.warehouse) {
      getOrders().then(res => {
        res.items.forEach((item: any, i: number) => {
          res.items[i].order = item.products
        });
        setOrders(res.items)
      })
    } else {
      getOrdersByName(get("name")).then(res => {
        res.forEach((item: any, i: number) => {
          res[i].order = item.products
        });
        setOrders(res)
      })
    }
    
  }

  useEffect(gOrders, [])

  const sShowProduct = async (set: boolean, i: number = -1) => {
    if (set) {
      console.log(orders)
      const iProducts = []
      for (let product of orders[i].order) {
        const result = await getProduct(product.sku)

        result.quantity = product.quantity

        iProducts.push(result)
      }
      setProducts(iProducts)

      const order = await getOrder(orders[i].uuid)
      setIsFullfilled(order.fullfilled)
      setShowProduct(i)
    } else {
      setShowProduct(-1)
      setProducts([])
    }
  }

  const reOrder = (order: any) => {
    window.localStorage.setItem("cart", JSON.stringify({ items: order }))
    setShowAlert(true)
  }

  const markOrder = (uuid: string) => {
    markOrderAsFullfilled(uuid).then(() => {
      setShowAlert2(true)
    })
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle color="primary">Orders</IonTitle>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonButtons slot="end">
            <IconButton />
            {props.warehouse ? null : <CartButton />}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonAlert isOpen={showAlert} header="Added to cart" message="To reorder, place the order in the cart." buttons={['Okay']} onDidDismiss={() => setShowAlert(false)}></IonAlert>
        <IonAlert isOpen={showAlert2} header="Marked as fullfilled" buttons={['Okay']} onDidDismiss={() => setShowAlert2(false)}></IonAlert>
        <IonList>
          {orders.map((order: { timestamp: number, order: any[], uuid: string, name: string, location: string, jobName: string, dateNeeded: string, pmName: string, jobNumber: string, deliveryInstructions: string }, i: number) => {
            return <>
              <IonItem detail={true} key={i} onClick={() => sShowProduct(true, i)}>
                <IonLabel>
                  {new Date(order.timestamp * 1000).toLocaleDateString() + " " +
                    new Date(order.timestamp * 1000).toLocaleTimeString() + " — " +
                    order.jobName + " — " + 
                    order.jobNumber
                  }
                </IonLabel>
                <IonNote slot="end">
                  {order.order.length + " products"}
                </IonNote>
                
              </IonItem>
              <IonModal isOpen={showProduct === i} onDidDismiss={() => setShowProduct(-1)}>
                <IonToolbar>
                  <IonTitle>{`${order.order.length} ${order.order.length === 1 ? "product" : "products"}`}</IonTitle>
                  <IonButtons slot="primary">
                    <IonButton onClick={() => sShowProduct(false, -1)}>Close</IonButton>
                  </IonButtons>
                  <IonButtons slot="start">
                    {props.warehouse ? <IonButton onClick={() => {markOrder(order.uuid)}}>Mark as Fullfilled</IonButton> : <IonButton onClick={() => { reOrder(order.order) }}>Reorder</IonButton>}
                  </IonButtons>
                </IonToolbar>
                <IonContent>
                  <IonList>
                    <IonItem>
                      <IonLabel>Fullfillment Status </IonLabel>
                      <IonNote>{isFullFilled ? "Fullfilled": "Waiting for fullfillment"}</IonNote>
                    </IonItem>
                    <IonItem>
                      <IonLabel>Name </IonLabel>
                      <IonNote>{order.name}</IonNote>
                    </IonItem>
                    <IonItem>
                      <IonLabel>Delivery Address </IonLabel>
                      <IonNote>{order.location}</IonNote>
                    </IonItem>
                    <IonItem>
                      <IonLabel>Job Name </IonLabel>
                      <IonNote>{order.jobName}</IonNote>
                    </IonItem>
                    <IonItem>
                      <IonLabel>Date Needed</IonLabel>
                      <IonNote>{order.dateNeeded}</IonNote>
                    </IonItem>
                    <IonItem>
                      <IonLabel>PM Name </IonLabel>
                      <IonNote>{order.pmName}</IonNote>
                    </IonItem>
                    <IonItem>
                      <IonLabel>Job Number</IonLabel>
                      <IonNote>{order.jobNumber}</IonNote>
                    </IonItem>
                    <IonItem>
                      <IonLabel>Delivery Instructions</IonLabel>
                      <IonNote>{order.deliveryInstructions}</IonNote>
                    </IonItem>
                  </IonList>
                  <IonGrid>
                    <IonRow>
                      {order.order.map((item, key) => <IonCol key={key} size="6"><Product product={products.length > key ? products[key] : item} warehouse={false} qcHandler={null} readOnly={true}></Product></IonCol>)}
                    </IonRow>
                  </IonGrid>
                </IonContent>
              </IonModal>
            </>
          })}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Orders;
